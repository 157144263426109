import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout';

/**
 * Basic blog page from Gatsby tutorial.
 * 
 * @param {*} param0 
 * @returns 
 */
const blogPage = ({ data }) => {
    return(
        <Layout pageTitle="Blog">
            {
                data.allMdx.nodes.map((node) => (
                <article key={node.id}>
                    <h2>
                      <Link to={`/blog/${node.slug}`}>
                        {node.frontmatter.title}
                      </Link>
                    </h2>
                    <p>Posted: {node.frontmatter.date}</p>
                </article>
                ))
            }
        </Layout>
    );
}

export const query = graphql`
    query MyQuery {
      allMdx(sort: {fields: frontmatter___date, order: DESC}) {
        nodes {
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
          }
          id
          slug
        }
      }
    }
    `

export default blogPage